import React, { Fragment } from "react"

import styles from "./styles.module.css";
import {isMobile,isIOS} from 'react-device-detect';
import FormField from "../../shared/formField";
import CountryCode from "../../shared/CountryCode";
import ProceedButton from "../proceedButton";
import GreenShape from "../../images/sign-up-form-green-shape.png";
import { B2XDropdownOptions, ClientTypeOptions_B2B2C, ClientTypeOptions_B2C } from "../../common/dropdowns";
import { B2X, CloudB2B2C_ProductIDs, CloudB2C_ProductIDs, RecaptchaPublicKey } from "../../common/constants";
import SelectDropDown from "../../shared/selectDropDown";
import investorsIcon  from "../../images/investorsIcon.svg";
import wealthIcon  from "../../images/wealthIcon.svg";
import { getCloudB2CProductIdFromTier, getCloudB2B2CProductIdFromTier, getCloudB2CProductIdFromClientType } from "../../common/gFunctions";
import { affiliateDiscountCheck, cloudSignUp, getUrlReferralCode, referralDiscountCheck, setUrlReferralCode } from "../../services/orderProcessing";
import { isValidationError } from "../../common/validation";
import AlertModal from "../../shared/alertModal";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { handleLogin } from "../../services/auth";
import GoogleReviewsSlider from "../googleReviewsSlider";

const queryString = require('query-string');

class SignUpForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                ProductId: -1,
                FirstName: '',
                LastName: '',
                CellCountryCode: '91',
                CellNum: '',
                EmailAddr: '',
                ClientType: -999
            },
            isBuyMode: false,
            hideTryMode: false
        }
    }

    componentDidMount() {
        var productId;
        var tier = this.props.location && this.props.location.state ? this.props.location.state.tier : null;
        if (!this.props.isWealth) {
            productId = tier != null ? getCloudB2CProductIdFromTier(tier) : CloudB2C_ProductIDs.PLUS;
        } else {
            productId = tier != null ? getCloudB2B2CProductIdFromTier(tier, 1000) : CloudB2B2C_ProductIDs.PLATINUM.P2000;
        }

        console.log(productId, this.props.location, 'productIdSignup');

        this.setState({
            form: {
                ...this.state.form,
                ProductId: productId
            },
            tierFromNavigation: tier
        });

        this.getSearchParameters();
    }

    checkAffiliateDiscount = (affiliateUTM) => {
        affiliateDiscountCheck(affiliateUTM)
        .then(({error, response}) => {
                console.log('affiliateUTM',error, response)
                if (error.status < 400) {
                    this.setState({
                        affiliateDiscount: response
                    })
                }
            })
    }

    checkReferralDiscount = (referralUTM) => {
        referralDiscountCheck(referralUTM)
        .then(({error, response}) => {
                console.log('referralUTM',error, response)
                if (error.status < 400) {
                    this.setState({
                        affiliateDiscount: response
                    })
                }
            })
    }

    handleUpdate = (e, type, isNumber=false) => {
        const value = isNumber ? +e.target.value : (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
        console.log(e,value,type);
        if (type) {
          this.setState({
            [type]: {
                ...this.state[type],
                [e.target.name]: value
            }
          })
        } else {
          this.setState({[e.target.name]: value});
        }
    }

    getSearchParameters() {
        var searchParamsFromStorage = window.localStorage.getItem("urlSearchParams");
  
        var search = (this.props.location.search == '' || this.props.location.search == null) ? searchParamsFromStorage : this.props.location.search;
  
        const searchParams = queryString.parse(search, {arrayFormat: 'bracket'});
        this.setState({
          searchParams
        });

        if (searchParams.r) {
            this.checkReferralDiscount(searchParams.r);
        } else if (searchParams.a) {
            this.checkAffiliateDiscount(searchParams.a);
        }

        this.setCodeFromURLorSession();
    }

    setCodeFromURLorSession() {
        const searchParamsFromURL = queryString.parse(this.props.location.search || '', {arrayFormat: 'bracket'});

        var code;
        var isCodeFromURL = true;
        if (searchParamsFromURL.referral || searchParamsFromURL.code) {
            code = searchParamsFromURL.referral || searchParamsFromURL.code;
        } else {
            code = getUrlReferralCode();
            if (code) {
                isCodeFromURL = false;
            }
        }

        if (code) {
            setUrlReferralCode(code);
            this.setState({isBuyMode: true, hideTryMode: isCodeFromURL === false, code});
        }
    }

    proceed = () => {
      var body = {...this.state.form};
  
      const validationError = isValidationError(body, [
        { name: 'FirstName', type: 'text' },
        { name: 'EmailAddr', type: 'text', validation: 'email'},
        { name: 'CellNum', type: 'text', validation: 'telno', countryCodeFieldName: 'CellCountryCode'},
        { name: 'ClientType', type: 'dropdown'}
      ]);

      console.log(validationError, body, 'proceed1');
  
      if (validationError || !body.AcceptedTermsAndConditions) {
        this.setState({dirty: true})
      } else {
        console.log(validationError, body, 'proceed2');
  
        this.setState({proceedLoading: true})
  
        body.MarketingInformationId = 1;

        if (!this.props.isWealth && this.state.tierFromNavigation === undefined) {
            body.ProductId = getCloudB2CProductIdFromClientType(body.ClientType)
        }

        var requestBody = {
          ProductId: body.ProductId,
          ClientType: body.ClientType,
          IdentityFields: {
            FirstName: body.FirstName,
            LastName: body.LastName,
            EmailAddr: body.EmailAddr || '',
            CellCountryCode: body.CellCountryCode,
            CellNum: body.CellNum
          },
          SearchParams: {
            ...this.state.searchParams
          }
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RecaptchaPublicKey, {action: 'signup'}).then((recaptchaToken) => {
              requestBody.RecaptchaToken = recaptchaToken;
              console.log(recaptchaToken,'recaptchaToken')
              cloudSignUp(requestBody).then(({error,response}) => {
                console.log(JSON.stringify(requestBody), error,response, 'proceed3');
                if (error.status >= 400) {
                  this.setState({
                    proceedLoading: false,
                    signupErrorModal: {
                      visible: true,
                      headerText: 'Signup Failed',
                      bodyText: ['Error in signing up. Please try again or contact MProfit Support']
                    }
                  })
                } else {
                  if (response.IsSuccess) {
                    setTimeout(() => {
                        if (this.state.isBuyMode && response.CustomerId && response.IsFree && response.ProductId == 600) {
                            handleLogin({email: requestBody.IdentityFields.EmailAddr, password: response.CustomerId, useCustomerId: true})
                                .then(({error,response}) => {
                                    if (error.status < 400) {
                                        navigate('/account/order/product');
                                    } else {
                                        navigate('/sign-up/' +(this.props.isWealth ? 'wealth' : 'investors') + '/success', {state: {email: requestBody.IdentityFields.EmailAddr, CustomerId: response.CustomerId, ProductId: response.ProductId, IsFree: response.IsFree}});
                                    }
                                })
                        } else {
                            navigate('/sign-up/' +(this.props.isWealth ? 'wealth' : 'investors') + '/success', {state: {email: requestBody.IdentityFields.EmailAddr, CustomerId: response.CustomerId, ProductId: response.ProductId, IsFree: response.IsFree}});
                        }
                    },1000)
                  } else if (this.state.isBuyMode && response.ErrorType === 'email_in_use') {
                    navigate('/buy?email='+encodeURIComponent(requestBody.IdentityFields.EmailAddr));
                  } else {
                    this.setState({
                      proceedLoading: false,
                      signupErrorModal: {
                        visible: true,
                        headerText: 'Signup Failed',
                        bodyText: [response.ErrorMessage]
                      }
                    })
                  }
                }
              })
            })
        });
      }
    }

    closeSignupErrorModal = (type) => {
        this.setState({
          signupErrorModal: undefined
        })
    }

    onB2XChange = (item) => {
        console.log('onB2XChange',item);
        navigate(item.id == B2X.B2C ? '/sign-up/investors' : '/sign-up/wealth');
    }

    onToggleBuyMode = () => {
        this.setState({isBuyMode: !this.state.isBuyMode});
    }

    render() {
        return (
            <>
            <Helmet>
                <script id="grecaptcha" src="https://www.google.com/recaptcha/api.js?render=6Lf-654UAAAAAFBeUNWxDq4xYA2DQO-oUhuXCqpY"></script>
            </Helmet>
            {this.renderPage()}
            </>
        )
    }

    renderPage = () => {
        const { Graphic, formOnRight, isWealth, headingText, bodyText } = this.props;

        const { signupErrorModal = {}, affiliateDiscount = 0, code } = this.state;
        console.log("🚀 ~ SignUpForm ~ this.state:", this.state)

        const affiliateDiscountFinal = code ? 0 : affiliateDiscount;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
          isMobileFinal = isMobile;
          isIOSFinal = isIOS;
        }

        if (formOnRight) {
            return (
                <>
                    <AlertModal isOpen={signupErrorModal.visible} toggle={this.closeSignupErrorModal} headerText={signupErrorModal.headerText} bodyText={signupErrorModal.bodyText || []} option2={{text:'Okay',onClick:this.closeSignupErrorModal}}/>
                    <div className={[styles.pageContainer].join(' ')}>
                        <div className={styles.topContainer}>
                            <div className={styles.b2xDropdownContainer}>
                                <div className={styles.b2xIcon}>
                                    <img src={isWealth ? wealthIcon : investorsIcon}/>
                                </div>
                                <SelectDropDown
                                    value={isWealth ? B2X.B2B2C : B2X.B2C} options={B2XDropdownOptions} mprdropDown={true} attachToBody={false} noBorderBottom={true}
                                    extraStyles={{
                                        container: {
                                            border: 'none',
                                            padding: '0px',
                                        },
                                        control: {
                                            '>div': {
                                                padding: 0,
                                                height: '29px',
                                                minHeight: 'none',
                                                alignItems: 'stretch',
                                            },
                                            backgroundColor: 'unset',
                                            justifyContent: 'flex-start'
                                        },
                                        singleValue: {
                                            fontSize: '16px',
                                            color: '#fff',
                                        },
                                        indicatorsContainer: {
                                            ' svg': {
                                                fill: '#fff'
                                            }
                                        }
                                    }}
                                    callBack={this.onB2XChange}
                                />
                            </div>
                            <div className={[styles.titleContainer,"d-flex flex-column flex-grow-11100"].join(" ")}>
                                <div className={[styles.title].join(' ')}>
                                    {headingText}
                                </div>
                                <div className={[styles.subtitle].join(' ')}>
                                    {bodyText}
                                </div>
                            </div>
                            <div className={["flex-grow-11100", styles.topExtraContainer].join(" ")}>
                                
                            </div>
                        </div>
                        <div className={[styles.contentContainer,this.props.isWealth && styles.contentContainerWealth].join(" ")}>
                            <div className={[styles.graphicContainer,styles.graphicContainerMargin,"flex-grow-11100"].join(" ")}>
                                <Graphic/>
                            </div>
                            <div className={[styles.formOuterContainer,"flex-grow-11100"].join(" ")}>
                                <Form state={this.state} handleUpdate={this.handleUpdate} proceed={this.proceed} affiliateDiscount={affiliateDiscountFinal} onToggleBuyMode={this.onToggleBuyMode} isBuyMode={this.state.isBuyMode} isWealth={this.props.isWealth}/>
                            </div>
                        </div>
                        <GoogleReviewsSlider />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <AlertModal isOpen={signupErrorModal.visible} toggle={this.closeSignupErrorModal} headerText={signupErrorModal.headerText} bodyText={signupErrorModal.bodyText || []} option2={{text:'Okay',onClick:this.closeSignupErrorModal}}/>
                    <div className={[styles.pageContainer].join(' ')}>
                        <div className={styles.topContainer}>
                            <div className={["flex-grow-11100", styles.topExtraContainer].join(" ")}>
                                
                            </div>
                            <div className={styles.b2xDropdownContainer}>
                                <div className={styles.b2xIcon}>
                                    <img src={isWealth ? wealthIcon : investorsIcon}/>
                                </div>
                                <SelectDropDown
                                    value={isWealth ? B2X.B2B2C : B2X.B2C} options={B2XDropdownOptions} mprdropDown={true} attachToBody={false} noBorderBottom={true}
                                    extraStyles={{
                                        container: {
                                            border: 'none',
                                            padding: '0px',
                                        },
                                        control: {
                                            '>div': {
                                                padding: 0,
                                                height: '29px',
                                                minHeight: 'none',
                                                alignItems: 'stretch',
                                            },
                                            backgroundColor: 'unset',
                                            justifyContent: 'flex-start'
                                        },
                                        singleValue: {
                                            fontSize: '16px',
                                            color: '#fff',
                                        },
                                        indicatorsContainer: {
                                            ' svg': {
                                                fill: '#fff'
                                            }
                                        }
                                    }}
                                    callBack={this.onB2XChange}
                                />
                            </div>
                            <div className={[styles.titleContainer,"d-flex flex-column flex-grow-11100"].join(" ")}>
                                <div className={[styles.title].join(' ')}>
                                    {headingText}
                                </div>
                                <div className={[styles.subtitle].join(' ')}>
                                    {bodyText}
                                </div>
                            </div>
                        </div>
                        <div className={[styles.contentContainer,this.props.isWealth && styles.contentContainerWealth].join(" ")}>
                            <div className={[styles.formOuterContainer,"flex-grow-11100"].join(" ")}>
                                <Form state={this.state} handleUpdate={this.handleUpdate} proceed={this.proceed} affiliateDiscount={affiliateDiscountFinal} onToggleBuyMode={this.onToggleBuyMode} isBuyMode={this.state.isBuyMode} isWealth={this.props.isWealth}/>
                            </div>
                            <div className={[styles.graphicContainer,styles.graphicContainerWealth,"flex-grow-11100"].join(" ")}>
                                <Graphic/>
                            </div>
                        </div>
                        <GoogleReviewsSlider />
                    </div>
                </>
            )
        }
    }
}

const getPercentString = (decimalValue) => (decimalValue * 100) + '%';

const Form = ({state, handleUpdate, proceed, affiliateDiscount, isBuyMode, onToggleBuyMode, isWealth}) => (
    <Fragment>
        <div className={[styles.formContainer,"flex-grow-11100"].join(" ")}>
            <div className={[styles.formTitle].join(' ')}>
                {isBuyMode ? `Buy now` : `Try now for free`}
                {
                    !state.hideTryMode &&
                    <div className={styles.orBuyNow}>
                        <span onClick={onToggleBuyMode}>{isBuyMode ? `or try now for free` : `or buy now`}</span>
                    </div>
                }
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName="FirstName" label="First Name" sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} perLine={2} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
                <FormField fieldName="LastName" label="Last Name" sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={true} dirty={state.dirty} perLine={2} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName="EmailAddr" label="Email" sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} perLine={1} hideOptionalText={true} customValidation={'email'} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName={'CellCountryCode'} label={'Country'} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} inputType="dropdown" options={CountryCode} optional={false} skipBootstrapClasses={true}  extraClass={styles.formFieldCountryCode} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
                <FormField fieldName={'CellNum'} label={'Tel Number'} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} sizeOverride={8} customValidation={'phone'} skipBootstrapClasses={true} extraClass={styles.formFieldPhoneNumber} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar} countryCodeFieldName={'CellCountryCode'}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName={'ClientType'} label={isWealth ? 'Please indicate your company profile' : 'What best describes you as an investor?'} perLine={1} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} inputType="dropdown" options={isWealth ? ClientTypeOptions_B2B2C : ClientTypeOptions_B2C} optional={false} dirty={state.dirty} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            {
                affiliateDiscount > 0 &&
                <div className={styles.affiliateDiscountRow}>
                    {`${getPercentString(affiliateDiscount)} discount will be applied ${isBuyMode ? `on this purchase` : `when you subscribe to a paid plan`}!`}
                </div>
            }
            <div className={[styles.termsOfUseRow].join(' ')}>
                <input className={["custom-input",styles.termsOfUseCheckbox,state.dirty && !state.form.AcceptedTermsAndConditions && styles.acceptTermsCheckboxInvalid].join(" ")} id="AcceptedTermsAndConditions" name="AcceptedTermsAndConditions" type="checkbox" checked={state.form.AcceptedTermsAndConditions} onChange={(event)=>handleUpdate(event,'form')} />
                <label className={[styles.acceptTermsCheckboxLabel].join(" ")} htmlFor="AcceptedTermsAndConditions"></label>
                <span className={styles.termsOfUseLabel}>I accept MProfit's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a></span>
            </div>
            <div className={[styles.proceedRow].join(' ')}>
                <ProceedButton buttonText={isBuyMode ? 'Proceed to buy' : 'Get started'} key={'proceed-form'} borderRadius={4} onClick={proceed} disabled={false} loading={state.proceedLoading} btnExtraClasses={styles.proceedBtn}/>
            </div>
            <img src={GreenShape} className={styles.greenShape}/>
        </div>
    </Fragment>
)

export default SignUpForm
