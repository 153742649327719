import React from "react"

import SignUpInvestorsGraphic from "../../gatsby-images/signup-investors-graphic";
import SignUpForm from "../signUpForm";

class SignUpInvestors extends React.Component {
    constructor(props) {
        super(props);
    }

    handleUpdate = () => {

    }

    proceed = () => {

    }

    render() {
        return <SignUpForm formOnRight={true} Graphic={SignUpInvestorsGraphic} proceed={this.proceed} isWealth={false} location={this.props.location}
            headingText={'Manage your family investments with MProfit'}
            bodyText={`Auto-import your trade data for Stocks, Mutual Funds & other assets from 700+ brokers. Track your consolidated XIRR, get Capital Gain reports and gain advanced portfolio insights!`}
        />
    }
}

export default SignUpInvestors
